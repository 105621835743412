import React from 'react';

const PageFooter = () => (
  <div className="footer">
    <hr />
    Copyright &copy;
    {' '}
    {new Date().getFullYear()}
    {' '}
    Sparrow Tech | All rights reserved
  </div>
);
export default PageFooter;
